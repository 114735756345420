<template>
  <div>
    <successRegister class=" mb-3 mt-5 pt-3" />
  </div>
</template>

<script>
import SuccessRegister from '@/components/cleanui/system/Auth/SuccessRegister'
export default {
  components: {
    successRegister: SuccessRegister,
  },
}
</script>
