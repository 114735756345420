<template>
  <div class="container-success-register">
    <img :src="urlImage"
      alt="Logo Success" />
    <div class="title-success">{{ $t('authForm.titleSuccessRegister') }}</div>
    <div class="sub-title-success">{{ $t('authForm.subTitleSuccessRegister') }}</div>
    <router-link to="/auth/login">
      <a-button type="primary" size="large" class="text-center w-100 btn-submit">
        {{ $t('authForm.signIn') }}
      </a-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'OtpConfirmation',
  data: function () {
    return {
    }
  },
  computed: {
    urlImage() {
      return this.$store.state.app.logo
    },
  },
}
</script>

<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>

<style lang="scss">
.container-success-register {
  width: 400px;
  margin: auto;
  text-align: center;
  padding: 15px;

  img {
    width: 100%;
  }
}

.title-success {
  color: #000000;
  font-size: 21px;
}

.sub-title-success {
  color: #000;
  font-size: 14px;
  margin-bottom: 30px;
}

.btn-submit {
  background-color: var(--biz-brand-button) !important;
  border-color: var(--biz-brand-button) !important;

  &:hover {
    background-color: var(--biz-brand-button) !important;
    border-color: var(--biz-brand-button) !important;
  }
}
</style>
